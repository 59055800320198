import React from "react";
import "./Skills.scss";

export default function Skills(props) {
  return (
    <div className="skills">
    <div className='home-button' onClick={() => props.history.goBack()}><i className="fas fa-arrow-left fa-3x"></i></div>
    <h1>My Skills</h1>
      <div className="react">
        <svg
          id="react"
          viewBox="0 0 1012 903"
          style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 1.5
          }}
        >
          <path
            d="M509.634,243.329l0.572,114.941c-36.811,0.183 -70.065,22.015 -84.87,55.718c-20.689,47.097 0.752,102.131 47.849,122.819c47.097,20.689 102.13,-0.752 122.819,-47.849c20.702,-47.128 -0.72,-102.116 -47.849,-122.819"
            style={{ fill: "none", stroke: "#01e1ff", strokeWidth: "30px" }}
          />
          <path
            d="M507.276,243.032c-40.298,-2.76 -80.837,3.077 -120.755,7.714c-87.667,10.183 -176.917,29.241 -256.916,67.473"
            style={{ fill: "none", stroke: "#01e1ff", strokeWidth: "30px" }}
          />
          <path
            d="M129.718,317.214c-23.094,9.849 -44.39,23.778 -64.099,39.228c-13.157,10.313 -24.551,22.811 -34.6,36.137c-62.876,83.376 13.073,150.494 86.7,186.219c148.365,71.987 373.362,92.006 534.215,66.695"
            style={{ fill: "none", stroke: "#01e1ff", strokeWidth: "30px" }}
          />
          <path
            d="M652.865,645.548c41.739,-7.259 84.56,-10.978 125.522,-22.049c32.08,-8.67 61.58,-22.345 91.539,-36.329c23.015,-10.742 44.86,-20.962 65.122,-36.503c23.177,-17.778 50.181,-38.955 61.715,-66.653c26.737,-64.207 -32.938,-124.573 -85.252,-152.292"
            style={{ fill: "none", stroke: "#01e1ff", strokeWidth: "30px" }}
          />
          <path
            d="M909.591,330.924c-89.812,-44.906 -185.775,-75.855 -286.605,-82.418"
            style={{ fill: "none", stroke: "#01e1ff", strokeWidth: "30px" }}
          />
          <path
            d="M617.907,247.059c-56.533,-76.724 -120.396,-148.709 -201.701,-199.771"
            style={{ fill: "none", stroke: "#01e1ff", strokeWidth: "30px" }}
          />
          <path
            d="M417.114,47.136c-18.377,-12.674 -39.826,-23.429 -60.718,-31.233c-14.205,-5.305 -29.854,-7.969 -45.012,-8.228c-14.318,-0.244 -28.943,1.138 -42.714,5.239c-38.631,11.505 -54.916,46.978 -60.496,83.756c-8.378,55.211 -6.777,111.545 3.833,166.465"
            style={{ fill: "none", stroke: "#01e1ff", strokeWidth: "30px" }}
          />
          <path
            d="M220.453,309.826c21.092,72.765 48.594,144.209 82.559,212.006"
            style={{ fill: "none", stroke: "#01e1ff", strokeWidth: "30px" }}
          />
          <path
            d="M304.949,522.672c18.459,35.8 39.511,69.051 63.085,101.655"
            style={{ fill: "none", stroke: "#01e1ff", strokeWidth: "30px" }}
          />
          <path
            d="M401.592,675.224c69.601,80.193 141.214,170.556 244.118,209.207c66.432,24.952 137.328,4.94 160.383,-67.358c7.431,-23.303 7.383,-48.168 7.935,-72.377c0.754,-33.073 -0.833,-67.486 -8.679,-99.804"
            style={{ fill: "none", stroke: "#01e1ff", strokeWidth: "30px" }}
          />
          <path
            d="M793.756,597.75c-25.25,-85.848 -55.018,-148.721 -96.787,-227.767c-17.391,-32.912 -32.679,-67.842 -55.388,-97.592"
            style={{ fill: "none", stroke: "#01e1ff", strokeWidth: "30px" }}
          />
          <path
            d="M401.91,226.523c22.848,-34.565 56.792,-71.699 87.703,-99.166"
            style={{ fill: "none", stroke: "#01e1ff", strokeWidth: "30px" }}
          />
          <path
            d="M520.908,100.496c45.921,-36.081 93.439,-75.641 151.888,-87.649c64.715,-13.295 113.983,0.612 130.582,71.559c5.353,22.88 8.266,46.336 8.756,69.829c0.727,34.846 -6.211,71.738 -12.884,106.073"
            style={{ fill: "none", stroke: "#01e1ff", strokeWidth: "30px" }}
          />
          <path
            d="M788.026,306.358c-10.277,39.396 -20.054,77.098 -36.001,114.751"
            style={{ fill: "none", stroke: "#01e1ff", strokeWidth: "30px" }}
          />
          <path
            d="M722.091,479.26c-20.254,49.51 -39.74,95.894 -71.655,139.143"
            style={{ fill: "none", stroke: "#01e1ff", strokeWidth: "30px" }}
          />
          <path
            d="M605.12,676.348c-28.099,32.284 -55.953,64.733 -84.792,96.361"
            style={{ fill: "none", stroke: "#01e1ff", strokeWidth: "30px" }}
          />
          <path
            d="M488.95,803.734c-112.329,97.494 -290.251,155.837 -290.287,-58.488c-0.005,-34.315 6.531,-68.761 15.09,-101.923"
            style={{ fill: "none", stroke: "#01e1ff", strokeWidth: "30px" }}
          />
          <path
            d="M220.558,592.225c9.666,-39.234 22.694,-76.907 38.351,-114.144"
            style={{ fill: "none", stroke: "#01e1ff", strokeWidth: "30px" }}
          />
          <path
            d="M283.85,423.178c15.918,-50.786 41.849,-105.445 77.365,-145.508"
            style={{ fill: "none", stroke: "#01e1ff", strokeWidth: "30px" }}
          />
        </svg>
        <h1>React</h1>
      </div>

      <div className="redux">
        <svg
          id="redux"
          viewBox="0 0 974 933"
          style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 1.5
          }}
        >
          <path
            d="M486.82,385.706c123.539,-7.059 256.363,48.125 342.259,136.087c44.824,45.904 80.57,109.193 78.883,175.132c-0.499,19.496 -4.656,39.667 -11.343,57.973c-31.024,84.917 -130.356,123.057 -214.961,113.035c-47.722,-5.654 -96.575,-26.728 -134.153,-56.563"
            style={{ fill: "none", stroke: "#eb00ff", strokeWidth: "30px" }}
          />
          <path
            d="M615.461,628.99c-60.492,110.308 -177.841,205.058 -302.308,229.145c-46.78,9.053 -103.051,12.019 -147.349,-8.61c-21.573,-10.046 -43.07,-22.609 -58.75,-40.778c-104.359,-120.926 -27.137,-275.448 105.364,-329.24"
            style={{ fill: "none", stroke: "#eb00ff", strokeWidth: "30px" }}
          />
          <path
            d="M213.812,481.783c13.589,52.091 27.855,106.363 60.501,150.087"
            style={{ fill: "none", stroke: "#eb00ff", strokeWidth: "30px" }}
          />
          <path
            d="M148.243,441.639c2.228,11.826 4.036,23.639 7.254,35.253"
            style={{ fill: "none", stroke: "#eb00ff", strokeWidth: "30px" }}
          />
          <path
            d="M279.942,634.233c-6.427,9.695 -9.844,20.981 -9.844,32.509c0,33.167 27.75,60.094 61.929,60.094c34.179,0 61.929,-26.927 61.929,-60.094c0,-33.167 -27.75,-60.094 -61.929,-60.094c-1.816,0 -3.631,0.078 -5.44,0.232"
            style={{ fill: "none", stroke: "#eb00ff", strokeWidth: "30px" }}
          />
          <path
            d="M325.416,602.561c-83.395,-145.346 -107.466,-402.325 50.505,-507.303c28.899,-19.205 62.761,-26.665 96.96,-27.697c144.427,-4.357 210.852,163.648 190.102,286.479"
            style={{ fill: "none", stroke: "#eb00ff", strokeWidth: "30px" }}
          />
          <path
            d="M205.663,450.966c-17.124,-108.969 -8.895,-224.429 53.659,-318.784c67.058,-101.148 229.758,-169.171 337.757,-89.804c59.357,43.621 99.405,100.461 119.395,171.605c4.252,15.133 19.143,80.075 13.319,93.018c-1.147,2.549 -5.156,-2.166 -7.791,-3.1c-8.921,-3.163 -18.119,-5.949 -27.255,-8.432"
            style={{ fill: "none", stroke: "#eb00ff", strokeWidth: "30px" }}
          />
          <path
            d="M663.218,354.055c-58.337,-26.434 -123.249,-28.612 -186.326,-24.17"
            style={{ fill: "none", stroke: "#eb00ff", strokeWidth: "30px" }}
          />
          <path
            d="M467.744,326.475c-11.668,-16.125 -30.677,-25.72 -50.955,-25.72c-34.384,0 -62.299,27.14 -62.299,60.569c0,33.429 27.915,60.569 62.299,60.569c34.407,0 44.779,-14.676 59.76,-37.208"
            style={{ fill: "none", stroke: "#eb00ff", strokeWwidth: "30px" }}
          />
          <path
            d="M673.909,644.799c30.789,-4.487 53.602,-30.639 53.602,-61.447c0,-34.28 -28.113,-62.11 -62.74,-62.11c-34.628,0 -63.571,27.84 -62.741,62.11c0.605,24.948 9.471,31.934 13.732,40.482"
            style={{ fill: "none", stroke: "#eb00ff", strokeWidth: "30px" }}
          />
          <path
            d="M146.502,441.665c-53.669,34.566 -98.993,93.499 -121.13,153.08c-35.728,96.163 -20.05,221.786 70.971,281.381c120.781,79.08 276.111,44.589 394.518,-25.493c12.535,-7.419 42.329,-26.511 55.868,-39.838"
            style={{ fill: "none", stroke: "#eb00ff", strokeWidth: "30px" }}
          />
          <path
            d="M569.875,790.676c40.795,-39.995 78.607,-89.686 100.394,-142.825"
            style={{ fill: "none", stroke: "#eb00ff", strokeWidth: "30px" }}
          />
          <path
            d="M701.756,367.408c59.897,21.455 115.093,58.805 160.225,103.245c26.534,26.127 51.713,55.806 69.146,88.885c45.711,86.737 54.733,215.615 -22.515,288.673c-66.301,62.705 -145.046,81.668 -234.697,75.275c-33.848,-2.413 -64.526,-13.721 -95.36,-26.969c-6.241,-2.681 -12.461,-5.407 -18.679,-8.14c-2.243,-0.986 -4.479,-1.984 -6.73,-2.953c-0.805,-0.347 -2.938,-0.338 -2.415,-1.042c5.434,-7.298 17.748,-13.526 24.934,-19.426"
            style={{ fill: "none", stroke: "#eb00ff", strokeWidth: "30px" }}
          />
        </svg>
        <h1>Redux</h1>
      </div>

      <div className="nodejs">
        <svg
          id="nodejs"
          viewBox="0 0 875 961"
          style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 1.5
          }}
        >
          <path
            d="M404.545,328.509c-5.859,4.261 -30.359,35.153 -33.555,57.522c-3.195,22.37 1.065,52.196 11.718,66.044c10.652,13.848 29.826,30.359 52.196,41.012c22.37,10.652 136.881,24.5 160.849,28.228c23.968,3.728 43.142,7.989 52.729,28.229c9.587,20.239 2.13,31.424 -7.457,46.337c-9.587,14.913 -70.837,19.174 -102.262,20.239c-31.424,1.065 -68.707,-8.522 -94.272,-24.5c-25.566,-15.978 -26.664,-50.174 -26.664,-50.174l-58.038,2.122c0,0 -5.31,13.965 2.679,42.193c7.99,28.229 22.903,44.207 44.207,61.251c21.305,17.043 111.849,31.956 178.958,27.163c67.11,-4.794 110.431,-47.679 110.431,-47.679c0,0 37.778,-39.587 23.275,-94.793c-14.503,-55.206 -65.498,-61.288 -80.002,-68.773c-14.503,-7.486 -160.573,-26.656 -160.573,-26.656c0,0 -34.518,0.457 -37.793,-27.614c-3.274,-28.071 21.96,-39.504 21.96,-39.504c0,0 -6.521,-8.216 86.112,-16.17c92.634,-7.953 96.859,68.945 96.859,68.945l60.907,-4.901c0,0 -5.248,-76.675 -66.068,-105.214c-60.82,-28.538 -178.4,-5.368 -178.4,-5.368l-139.834,0.791l1.891,423.182c0,0 -9.545,60.783 -54.517,79.99c-44.973,19.207 -100.837,-1.102 -100.837,-1.102l-130.885,-72.127c0,0 -19.859,-21.737 -22.319,-29.116c-2.46,-7.379 -7.043,-26.393 -7.043,-26.393l-1.149,-408.742c0,0 9.867,-32.452 16.631,-38.294c6.764,-5.842 36.058,-25.648 36.058,-25.648l337.808,-193.601c0,0 16.547,-7.74 40.986,-7.74c24.438,0 38.781,12.375 38.781,12.375l360.49,203.348c0,0 20.016,19.433 25.581,33.345l2.4,34.293l-0.421,380.387c0,0 3.426,23.498 -9.373,43.531c-12.799,20.033 -27.546,27.211 -27.546,27.211l-345.421,195.635c0,0 -20.398,14.929 -48.406,15.38c-28.007,0.452 -38.03,-10.944 -38.03,-10.944l-125.284,-76.907l62.211,-28.416l102.939,54.017l364.759,-207.813l-2.106,-410.143l-362.455,-204.898l-363.85,206.965l-1.916,408.304l105.668,62.766c0,0 27.979,16.217 56.415,1.581c28.436,-14.636 24.15,-29.661 24.15,-29.661l4.782,-411.608l32.388,0.089"
            style={{ fill: "none", stroke: "#29e500", strokeWidth: "30px" }}
          />
        </svg>
        <h1>Node.js</h1>
      </div>

      <div className="express">
        <svg
          id="express"
          viewBox="0 0 1199 1198"
          style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 1.5
          }}
        >
          <path
            d="M522.02,566.684l-186.697,-0.732c0,0 1.872,-92.665 92.466,-93.379c76.932,-0.606 92.365,61.631 92.365,61.631"
            style={{ fill: "none", stroke: "#fff", strokeWidth: "30px" }}
          />
          <path
            d="M1000.81,763.269c83.329,-203.735 0.134,-437.826 -193.346,-544.027c-210.005,-115.271 -473.835,-38.823 -588.793,170.611c-114.959,209.435 -37.793,473.055 172.212,588.327c210.146,115.349 473.758,38.964 588.793,-170.611l-58.152,-43.751l-129.29,-178.623l121.091,-160.166l-65.875,0.131l-89.201,118.167l-81.576,-117.824l-68.801,-0.388l113.925,162.699l-54.125,77.52l-144.418,-1.059c0,0 -10.6,61.959 -91.542,59.385c-80.941,-2.574 -98.609,-79.564 -98.393,-91.45c0.216,-11.886 -0.749,-22.263 -0.749,-22.263l246.107,1.03c0,0 12.618,-190.365 -146.244,-188.771c-158.862,1.594 -157.533,175.466 -157.268,191.936c0.266,16.471 14.424,159.91 158.734,158.504c95.14,-0.927 135.535,-81.982 135.535,-81.982l83.594,0.555l-57.708,77.416l70.187,0.886l93.758,-138.486l94.625,138.571l35.469,-0.418"
            style={{ fill: "none", stroke: "#fff", strokeWidth: "30px" }}
          />
        </svg>
        <h1>Express</h1>
      </div>

      <div className="html5">
        <svg
          id="html5"
          width="110%"
          height="100%"
          viewBox="0 0 839 952"
          style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 1.5
          }}
        >
          <path
            d="M387.933,933.054l-304.154,-85.804l-76.131,-838.102l822.885,-1.5l-73.846,835.031l-337.628,100.799l0.418,-176.38"
            style={{ fill: "none", stroke: "#fed128", strokeWidth: "30px" }}
          />
          <path
            d="M419.477,767.098l214.504,-63.323l25.312,-311.263l-374.972,-10.335l-6.362,-102.742l395.915,-0.67l6.83,-100.666l-516.664,0.669l25.027,308.518l358.281,6.361l-11.467,126.068l-116.497,34.396l-114.221,-31.651l-6.244,-77.832l-104.83,-1.786l10.982,163.209l197.947,55.19"
            style={{ fill: "none", stroke: "#fff", strokeWidth: "30px" }}
          />
        </svg>
        <h1>HTML5</h1>
      </div>

      <div className="css3">
        <svg
          id="css3"
          width="110%"
          height="100%"
          viewBox="0 0 839 952"
          style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 1.5
          }}
        >
          <path
            d="M369.385,927.193l-286.92,-81.834l-74.817,-835.606l808.954,-2.105l-76.941,839.775l-328.389,86.446l-0.456,-174.675"
            style={{ fill: "none", stroke: "#28fefe", strokeWidth: "30px" }}
          />
          <path
            d="M410.816,759.194l194.529,-56.35l31.396,-311.142l-223.483,-5.272l228.877,-87.345l17,-117.706l-494.582,-2.04l10.437,113.803l235.068,3.664l-225.934,89.034l10.011,116.073l320.007,0l-9.211,118.482l-93.416,28.485l-89.919,-29.809l-12.346,-54.7l-109.348,-1.617l10.23,144.628l162.087,46.237"
            style={{ fill: "none", stroke: "#fff", strokeWidth: "30px" }}
          />
        </svg>
        <h1>CSS3</h1>
      </div>

      <div className="javascript">
        <svg
          id="javascript"
          viewBox="0 0 843 958"
          style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 1.5
          }}
        >
          <path
            d="M388.893,941.24l-306.36,-88.334l-74.885,-845.258l827.222,0.904l-74.707,844.692l-339.148,96.873l-1.192,-199.686"
            style={{ fill: "none", stroke: "#ffda3e", strokeWidth: "30px" }}
          />
          <path
            d="M419.823,750.431l-273.368,-73.051l-2.864,-95.964l163.356,43.616l0.66,-435.602l77.601,1.249l-2.142,522.778l71.506,-1.533l-0.158,-66.916l159.764,-40.212l2.077,-83.842l-160.317,25.849l-3.098,-355.693l264.825,-1.152l-9,97.723l-175.145,-0.921l0.442,147.426l164.161,-18.214l-20.79,268.143l-222.585,57.722"
            style={{ fill: "none", stroke: "#fff", strokeWidth: "30px" }}
          />
        </svg>
        <h1>Javascript</h1>
      </div>

      <div className="postgresql">
        <svg
          id="postgresql"
          viewBox="0 0 836 867"
          style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 1.5
          }}
        >
          <path
            d="M652.041,591.663c49.937,5.708 152.508,4.375 175.83,-53.356c1.518,-3.759 -8.101,-0.361 -12.155,-0.434c-8.863,-0.16 -17.734,-0.09 -26.599,-0.109c-26.535,-0.058 -56.545,5.047 -82.216,-3.979"
            style={{ fill: "none", stroke: "#fff", strokeWidth: "30px" }}
          />
          <path
            d="M706.871,530.382c-52.585,-55.872 -85.714,-117.838 -111.486,-190.412c-9.326,-26.262 -18.997,-73.239 3.854,-96.293c22.404,-22.603 88.18,-7.359 95.637,-13.382"
            style={{ fill: "none", stroke: "#fff", strokeWidth: "30px" }}
          />
          <path
            d="M697.195,230.427c-1.714,29.996 -9.226,59.368 -9.243,89.505c-0.028,49.695 16.906,95.441 -1.435,143.947"
            style={{ fill: "none", stroke: "#fff", strokeWidth: "30px" }}
          />
          <path
            d="M672.199,531.995c-17.85,10.242 -21.246,15.598 -22.694,36.421"
            style={{ fill: "none", stroke: "#fff", strokeWidth: "30px" }}
          />
          <path
            d="M706.065,497.323c57.847,-89.815 138.172,-285.604 111.426,-391.186"
            style={{ fill: "none", stroke: "#fff", strokeWidth: "30px" }}
          />
          <path
            d="M814.113,105.446c-48.308,-70.047 -136.772,-106.46 -221.314,-96.041c-27.277,3.362 -55.018,15.675 -80.487,18.53c-7.743,0.868 -21.131,-5.892 -29.39,-7.039c-26.426,-3.669 -53.94,-4.271 -80.417,-1.008c-20.319,2.504 -39.386,8.111 -56.72,19.135c-6.032,3.836 -14.31,12.35 -21.115,14.307"
            style={{ fill: "none", stroke: "#fff", strokeWidth: "30px" }}
          />
          <path
            d="M322.053,54.349c-56.81,55.674 -77.292,137.12 -77.63,214.851c-0.091,20.799 3.557,41.565 3.179,62.298c-1.479,81.219 -27.21,111.21 41.576,172.768"
            style={{ fill: "none", stroke: "#fff", strokeWidth: "30px" }}
          />
          <path
            d="M286.112,505.608c-16.822,22.831 -35.25,44.22 -53.181,66.207c-54.211,66.472 -83.442,60.529 -126.047,-14.409"
            style={{ fill: "none", stroke: "#fff", strokeWidth: "30px" }}
          />
          <path
            d="M107.119,559.104c-21.548,-29.958 -32.364,-65.222 -45.703,-99.214c-17.026,-43.386 -27.719,-89.955 -37.167,-135.506c-19.081,-91.998 -41.647,-228.264 57.634,-285.166c55.671,-31.906 144.837,-18.984 203.867,-3.231"
            style={{ fill: "none", stroke: "#fff", strokeWidth: "30px" }}
          />
          <path
            d="M543.555,40.478c14.466,8.921 30.76,14.819 44.714,24.557c41.639,29.061 85.648,89.078 100.315,137.294"
            style={{ fill: "none", stroke: "#fff", strokeWidth: "30px" }}
          />
          <path
            d="M638.879,231.127c-0.158,11.103 -1.953,22.834 -0.861,33.892c0.127,1.289 2.351,-1.085 3.512,-1.656c2.448,-1.204 4.57,-2.829 6.92,-4.172c0.073,-0.041 -2.07,1.745 -3.294,2.761c-1.8,1.494 -4.597,4.842 -7.084,4.693c-2.602,-0.155 -5.03,-0.873 -7.285,-2.156c-0.667,-0.38 -1.305,-0.813 -1.916,-1.277c-0.414,-0.315 -1.725,-1.095 -1.219,-0.974c2.82,0.676 5.631,2.395 8.374,3.382"
            style={{ fill: "none", stroke: "#fff", strokeWidth: "30px" }}
          />
          <path
            d="M416.204,620.685c2.172,52.128 -7.425,140.444 13.57,186.937c21.287,47.138 61.986,57.091 109.597,47.582c25.561,-5.106 51.068,-16.597 66.587,-38.588c19.536,-27.683 22.353,-67.192 25.265,-99.813c2.974,-33.302 7.319,-66.475 10.369,-99.782"
            style={{ fill: "none", stroke: "#fff", strokeWidth: "30px" }}
          />
          <path
            d="M268.122,247.89c13.534,-6.556 27.843,-10.796 42.448,-14.132c69.185,-15.799 86.335,20.299 91.407,81.495c3.491,42.112 1.357,78.016 -9.595,119.13c-6.055,22.731 -20.277,42.922 -27.707,65.402c-5.293,16.015 -4.072,34.904 -10.214,50.121c-2.454,6.082 -6.959,12.073 -12.088,16.152c-4.931,3.921 -12.703,12.328 -18.719,14.153c-16.033,4.866 -77.797,8.599 -69.197,32.949c3.772,10.682 30.613,15.657 40.301,17.326c39.431,6.795 91.619,-0.377 116.259,-35.757c14.292,-20.523 3.702,-58.262 -23.216,-59.901"
            style={{ fill: "none", stroke: "#fff", strokeWidth: "30px" }}
          />
          <path
            d="M304.717,513.369c9.834,6.5 17.396,9.148 29.308,9.589"
            style={{ fill: "none", stroke: "#fff", strokeWidth: "30px" }}
          />
          <path
            d="M346.604,231.644c0.693,10.54 0.945,21.096 1.199,31.654c0.034,1.437 -0.811,9.126 0.085,9.815c0.84,0.646 6.16,-2.249 7.585,-2.775c1.04,-0.383 3.904,-1.905 3.136,-1.106c-7.657,7.966 -14.959,4.801 -22.84,-0.595c-1.147,-0.786 2.538,1.135 3.813,1.689c2.723,1.183 5.412,2.457 8.087,3.745"
            style={{ fill: "none", stroke: "#fff", strokeWidth: "30px" }}
          />
        </svg>
        <h1>PostgreSQL</h1>
      </div>

      <div className="sass">
        <svg
          id="sass"
          viewBox="0 0 1277 1278"
          style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 1.5
          }}
        >
          <path
            d="M426.04,969.679l-24.656,48.68c188.539,117.282 435.605,76.553 578.619,-95.385c157.941,-189.883 133.134,-471.336 -55.363,-628.123c-188.497,-156.788 -469.76,-129.92 -627.701,59.963c-158.046,190.01 -133.259,471.23 55.364,628.123"
            style={{ fill: "none", stroke: "#cd669a", strokeWidth: "30px" }}
          />
          <path
            d="M427.011,969.815c76.62,26.303 146.922,-49.568 160.311,-119.141c3.459,-17.976 4.127,-37.721 1.344,-55.829c-1.186,-7.716 -4.966,-16.643 -5.021,-24.331c-0.001,-0.209 4.062,-0.822 4.456,-0.891c10.378,-1.809 20.861,-3.658 31.414,-3.72c31.725,-0.185 80.153,5.896 102.021,31.384c18.522,21.589 21.994,53.377 5.156,76.746c-3.777,5.242 -9.689,8.003 -13.722,12.733c-0.965,1.131 2.734,-1.171 4.102,-1.755c2.775,-1.185 5.568,-2.302 8.249,-3.696c52.332,-27.212 47.482,-83.609 4.899,-118.667c-37.566,-30.928 -118.564,-42.916 -163.32,-21.638"
            style={{ fill: "none", stroke: "#fff", strokeWidth: "30px" }}
          />
          <path
            d="M566.551,740.368c-27.824,-21.862 -55.695,-43.091 -82.12,-66.702c-29.258,-26.142 -59.498,-50.33 -59.688,-93.319c-0.191,-43.504 29.374,-79.239 60.166,-107.043c59.529,-53.751 167.66,-103.168 247.178,-114.791c31.468,-4.6 71.833,-7.746 101.321,7.448c46.168,23.789 44.531,56.189 23.727,98.178c-22.82,46.057 -76.222,78.467 -122.54,96.63c-43.495,17.057 -98.467,25.453 -142.34,5.436c-11.491,-5.243 -19.646,-12.9 -29.77,-19.809c-0.803,-0.548 0.489,1.88 0.751,2.816c0.49,1.753 1.004,3.499 1.601,5.218c1.792,5.168 4.425,10.016 7.694,14.399c27.944,37.471 80.073,41.217 122.653,38.764c84.277,-4.857 169.93,-52.337 208.912,-128.41c18.437,-35.981 28.529,-74.926 11.227,-113.731c-22.717,-50.951 -87.098,-65.826 -138.113,-68.315c-126.054,-6.149 -252.879,56.738 -344.864,139.687c-34.36,30.985 -92.292,97.664 -79.081,148.793"
            style={{ fill: "none", stroke: "#fff", strokeWidth: "30px" }}
          />
          <path
            d="M354.141,587.443c4.085,14.618 8.224,28.199 16.21,41.307c31.032,50.939 109.863,93.987 157.84,129.017"
            style={{ fill: "none", stroke: "#fff", strokeWidth: "30px" }}
          />
          <path
            d="M527.989,759.354c-24.989,14.107 -51.077,27.817 -73.968,45.173c-14.916,11.309 -28.651,24.479 -40.437,39.039c-19.085,23.577 -41.569,64.938 -1.667,81.702c5.671,2.382 11.655,3.776 17.757,4.48c8.571,0.989 17.282,0.381 25.724,-1.33c43.314,-8.78 76.587,-39.577 89.97,-81.945c4.485,-14.198 3.887,-29.552 3.421,-44.249c-0.082,-2.562 1.03,-12.469 -0.607,-14.214c-0.367,-0.391 -7.128,3.448 -7.712,3.749c-8.872,4.571 -17.35,9.838 -25.61,15.428c-30.011,20.31 -67.289,52.421 -73.984,89.936"
            style={{ fill: "none", stroke: "#fff", strokeWidth: "30px" }}
          />
        </svg>
        <h1>Sass</h1>
      </div>
      <div className="socket-io">
        <svg
          id="socket-io"
          viewBox="0 0 1069 1069"
          style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 1.5
          }}
        >
          <path
            d="M513.306,565.419l252.803,-364.394l-363.017,297.577l108.836,-0.689"
            style={{ fill: "none", stroke: "#fff", strokeWidth: "40px" }}
          />
          <path
            d="M395.515,795.49l272.09,-230.071l-154.914,3.009l-270.151,401.013c131.377,88.169 295.537,112.311 446.735,65.699c276.592,-85.268 431.922,-379.053 346.653,-655.645c-85.269,-276.592 -379.054,-431.922 -655.646,-346.653c-276.591,85.269 -431.921,379.054 -346.653,655.645c21.38,69.349 101.449,172.406 148.901,227.312"
            style={{ fill: "none", stroke: "#fff", strokeWidth: "40px" }}
          />
        </svg>
        <h1>Socket.IO</h1>
      </div>
    </div>
  );
}

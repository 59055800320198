import React from "react";
import "./About.scss";

export default function About(props) {
  return (
    <div className="about">
      <div className="home-button" onClick={() => props.history.goBack()}>
        <i className="fas fa-arrow-left fa-3x" />
      </div>
      <div>
        <h1>About Jonathan</h1>
        <h3>
          Jonathan is a working musician-turned web developer. His background
          has turned him into a creative problem solver. He loves learning about
          new tools and technologies, and then putting them to use.
        </h3>
      </div>
      <svg
        id='piano'
        viewBox="0 0 988 1000"
        width='100%'
        height='100%'
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 1.5
        }}
      >
        <path
          d="M20.187,625.908c18.019,64.487 37.585,129.019 57.717,192.879c5.767,18.295 13.033,59.251 41.281,47.781c8.677,-3.524 7.93,-43.411 7.937,-51.554c0.029,-36.217 2.702,-72.909 5.942,-108.965c0.992,-11.027 -0.793,-31.016 6.878,-39.798c10.178,-11.65 28.764,-0.353 39.973,3.237c37.173,11.907 73.205,28.729 109.867,42.334"
          style={{ fill: "none", stroke: "#ffd6ff", strokeWidth: "18px" }}
        />
        <path
          d="M15.417,620.618c-0.42,-29.447 -0.544,-59.066 -0.274,-88.519c0.096,-10.491 -1.319,-26.142 11.073,-30.488c10.7,-3.752 51.411,-0.667 65.835,-1.329c5.848,-0.269 17.023,1.502 22.093,-2.088c6.049,-4.284 4.25,-18.021 4.551,-24.43c0.825,-17.502 -4.49,-56.676 0.318,-71.075c3.471,-10.391 29.306,-7.371 36.853,-7.167c35.98,0.975 72.55,1.886 108.534,0.372"
          style={{ fill: "none", stroke: "#ffd6ff", strokeWidth: "18px" }}
        />
        <path
          d="M267.053,396.026c32.009,-34.009 56.291,-81.236 79.749,-121.646c31.271,-53.868 64.916,-107.032 98.077,-159.761c14.451,-22.978 47.733,-88.855 75.456,-95.588c13.399,-3.254 38.525,-6.597 51.524,-1.115c19.412,8.187 33.044,31.5 45.344,47.445c24.237,31.422 73.358,109.407 104.703,129.193c44.53,28.11 164.899,9.195 221.993,14.456"
          style={{ fill: "none", stroke: "#ffd6ff", strokeWidth: "18px" }}
        />
        <path
          d="M945.725,209.243c22.566,3.924 25.304,26.716 16.86,44.475c-6.831,14.366 -17.627,26.548 -26.29,39.78c-15.765,24.08 -30.576,48.746 -46.444,72.77c-0.982,1.487 -13.952,0.733 -15.99,0.815c-16.366,0.654 -32.655,2.352 -49.022,2.971c-53.836,2.034 -108.205,-2.209 -162.094,-2.238c-77.179,-0.04 -154.308,1.947 -231.527,1.373c-29.006,-0.216 -34.641,-1.81 -63.66,-0.65c-7.089,0.283 -21.912,-1.783 -28.125,1.211c-1.28,0.617 -1.473,10.207 -1.55,11.854c-0.2,4.227 -1.161,19.079 -0.219,23.198c0.027,0.118 2.838,0.266 3.121,0.296c10.435,1.099 20.872,1.763 31.367,1.961c31.9,0.604 40.448,-2.236 72.372,-1.545c66.313,1.436 132.687,-1.166 199.003,-1.671c35.325,-0.269 70.539,2.86 105.81,2.878c47.708,0.024 95.002,-4.781 142.773,-1.69"
          style={{ fill: "none", stroke: "#ffd6ff", strokeWidth: "18px" }}
        />
        <path
          d="M895.293,404.894c19.14,9.271 63.2,9.379 75.712,23.193c4.721,5.212 -16.335,14.74 -20.176,15.076c-24.169,2.121 -61.496,-2.094 -86.713,-2.54c-35.267,-0.625 -74.381,-6.159 -107.493,9.31c-26.205,12.244 -44.99,37.661 -68.5,54.031"
          style={{ fill: "none", stroke: "#ffd6ff", strokeWidth: "18px" }}
        />
        <path
          d="M688.029,504.201c-19.659,14.141 -40.717,13.407 -64.313,14.41c-40.967,1.74 -82.142,1.3 -123.144,1.39c-12.289,0.027 -43.755,-4.521 -54.683,0.771c-6.068,2.938 -5.652,14.214 -5.705,19.757c-0.182,18.995 0.595,37.802 0.958,56.78c0.068,3.517 1.615,18.144 -1.364,20.734c-10.926,9.5 -56.868,5.777 -71.97,5.705c-5.837,-0.028 -19.514,-3.114 -24.514,0.309c-1.04,0.712 -0.92,6.395 -1.061,7.661"
          style={{ fill: "none", stroke: "#ffd6ff", strokeWidth: "18px" }}
        />
        <path
          d="M343.176,631.538c1.3,22.755 0.497,45.779 -0.924,68.513c-0.84,13.431 -3.296,28.124 -1.509,41.522c2.134,16.006 8.869,31.079 13.373,46.467c11.598,39.631 24.529,78.943 35.388,118.781c5.271,19.337 12.731,59.578 24.581,75.156c1.757,2.31 9.607,2.455 12.18,2.513c17.284,0.393 20.98,-5.342 22.325,-22.384"
          style={{ fill: "none", stroke: "#ffd6ff", strokeWidth: "18px" }}
        />
        <path
          d="M449.338,962.133c1.812,-63.434 6.801,-126.673 10.098,-190.044c0.872,-16.746 2.337,-33.477 4.327,-50.127c0.405,-3.387 -0.418,-14.996 2.327,-17.469c3.941,-3.553 10.207,-3.372 15.133,-3.755c13.219,-1.027 26.671,0.669 39.94,0.417c21.852,-0.415 43.594,-2.049 65.461,-1.526c26.502,0.633 54.316,3.284 80.323,-3.321c13.889,-3.527 24.187,-13.103 34.658,-22.316c11.629,-10.232 23.899,-19.575 35.842,-29.41c9.772,-8.047 18.545,-16.43 30.84,-20.441"
          style={{ fill: "none", stroke: "#ffd6ff", strokeWidth: "18px" }}
        />
        <path
          d="M769.513,624.835c14.723,-3.627 30.424,-2.326 45.458,-2.409c19.188,-0.104 38.41,-1.373 57.598,-0.584"
          style={{ fill: "none", stroke: "#ffd6ff", strokeWidth: "18px" }}
        />
        <path
          d="M872.854,621.198c7.675,22.357 13.566,45.299 20.528,67.883c9.455,30.675 20.055,61.094 30.017,91.612c1.956,5.989 4.041,14.835 8.701,19.461c6.653,6.606 21.836,3.051 26.373,-4.361c5.993,-9.79 4.509,-24.208 4.985,-35.21"
          style={{ fill: "none", stroke: "#ffd6ff", strokeWidth: "18px" }}
        />
        <path
          d="M963.026,760.864c3.127,-54.719 7.555,-109.809 9.135,-164.568c1.067,-36.993 0.557,-74.167 0.491,-111.181"
          style={{ fill: "none", stroke: "#ffd6ff", strokeWidth: "18px" }}
        />
        <path
          d="M186.837,433.892c33.826,13.53 38.984,14.279 73.591,25.436c47.704,15.38 94.182,34.417 142.326,48.466"
          style={{ fill: "none", stroke: "#ffd6ff", strokeWidth: "18px" }}
        />
        <path
          d="M152.542,511.037c83.658,22.707 157.552,53.855 240.663,78.589"
          style={{ fill: "none", stroke: "#ffd6ff", strokeWidth: "18px" }}
        />
        <path
          d="M75.622,537.588c82.482,29.785 147.34,44.389 229.789,74.311"
          style={{ fill: "none", stroke: "#ffd6ff", strokeWidth: "18px" }}
        />
      </svg>
    </div>
  );
}
